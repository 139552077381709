<template>
  <div></div>
</template>
<script>
import i18n from '@/i18n/i18n';
import router, { rolesMap } from '../router/index';
import store from '@/store';
import state from '../store/index';

export default {
  name: 'SocialLoginView',
  components: {},
  data: () => ({
    token: null,
  }),
  created() {
    this.token = this.$route.query.token;

    if (this.token) {
      this.login();
    } else {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    login() {
      this.$store
        .dispatch('auth/socialLogin', this.token)
        .then((res) => {
          if (res.success) {
            if (res.user.role_id !== 3) {
              res.user.role_id = parseInt(res.user.role.id);
              state.dispatch('auth/setUser', res.user);
              state.dispatch('auth/setRole', res.user);
              state.dispatch('auth/setAuthToken', this.token);
              state.dispatch('auth/setAuthenticated', true);
              state.dispatch('auth/setOrganisationType', res.user.organisation.type);

              if (res.user.language) {
                localStorage.setItem('currentLanguage', res.user.language);
                this.$store.dispatch('language/changeLanguage', res.user.language);
              }

              res.user.role_id !== 1 && state.dispatch('auth/setCustomer', res.customer);

              const userRole = rolesMap.find((e) => e.id === parseInt(store.getters['auth/role']));
              if (this.$deviceToken) {
                // eslint-disable-next-line no-console
                this.submitToken(this.$deviceToken).catch(() => console.log('error saving token'));
              }

              router.push({ name: userRole.redirect }).catch((err) => {
                // Ignore the vuex err regarding  navigating to the page they are already on.
                if (
                  err.name !== 'NavigationDuplicated' &&
                  !err.message.includes('Avoided redundant navigation to current location')
                ) {
                  // But print any other errors to the console
                  // eslint-disable-next-line no-console
                  console.log(err);
                }
              });
              localStorage.setItem('loggedIn', true);

              localStorage.setItem('LimoExpressToken', this.token || null);
              localStorage.setItem('LimoExpressUser', res.user ? JSON.stringify(res.user) : null);
              localStorage.setItem('saveToken', true);
              localStorage.setItem('customer', res.customer ? JSON.stringify(res.customer) : null);
              localStorage.setItem('organisation_type', res.user.organisation.type || null);
            }
          } else {
            if (res.code === 'LE-001') {
              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.loginFailed'), color: 'red' });
            } else if (res.code) {
              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.organisationSuspended'), color: 'red' });
            } else {
              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
            }
          }
        })
        .catch((e) => {
          if (e.response.data.code === 'LE-001') {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.loginFailed'), color: 'red' });
          } else if (e.response.data.code) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.organisationSuspended'), color: 'red' });
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async submitToken(token) {
      await this.$store.dispatch('firebaseToken/submitToken', { token });
    },
  },
};
</script>
